/* .cm-contact-wrapper .cm-left-col ul li {
  width: calc(33.33% - 30px);
  margin: 0 15px;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 600;
}

.cm-contact-wrapper .cm-left-col ul li i {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin: 0 auto 10px;
  font-size: 24px;
}

.cm-contact-wrapper .cm-left-col ul li address {
  font-style: normal;
}

.cm-contact-wrapper .cm-right-col h3 {
  margin-bottom: 20px;
}

.cm-contact-wrapper .cm-right-col {
  margin-top: 30px;
}

.cm-contact-wrapper .cm-right-col button {
  font-size: 18px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
} */
.cm-left-col h3 {
  margin-bottom: 3rem;
}

.cm-right-col form {
  background: #fff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.cm-right-col .cm-form-field input,
.cm-right-col .cm-form-field textarea {
  border: none;
  border-bottom: 1px solid #efefef;
  border-radius: 0;
  padding: 10px 10px 10px 0;
}

.cm-right-col .cm-form-field-half>.cm-form-field {
  width: 100%;
  padding-right: 0px;
}

.cm-right-col .cm-form-field-submit button {
  background: none;
  border: 1px solid #024594;
  color: #024592;
}

.cm-left-col .icon {
  background: #024594;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.cm-left-col .icon i {
  color: #fff;
  font-size: 20px;
}

.cm-left-col .main p {
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 5px;
  line-height: 1.1;
}

.cm-left-col .main span a {
  font-size: 14px;
  font-weight: 700;
}

.contact-area .flex {
  margin-bottom: 20px;
}

@media(max-width:767px) {
  .cm-left-col h3 {
    margin-bottom: 1rem;
    font-size: 20px;
  }

  .cm-left-col .icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .cm-left-col .main p {
    font-size: 14px;
  }

  .cm-left-col .icon i {
    font-size: 16px;
  }

  .cm-right-col form {
    padding: 15px;
    border-radius: 10px;
  }

  .cm-right-col .cm-form-field {
    margin-bottom: 15px;
  }

  .cm-form-field-submit button {
    padding: 10px 0;
  }
}

@media screen and (max-width: 767px) {
  .cm-contact-wrapper .cm-left-col ul li {
    width: 100%;
  }

  .cm-contact-wrapper .cm-left-col ul {
    flex-wrap: wrap;
  }

  .cm-form-field-half>.cm-form-field {
    width: 100%;
    padding: 0 !important;
  }

  .cm-form-field-half,
  .cm-form-field-third,
  .cm-form-field-fourth {
    flex-wrap: wrap;
  }
}

.error {
  color: red;
}